import { createDefault } from "../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "社長", enum: "PRESIDENT" },
  { value: 2, text: "システム" },
  { value: 3, text: "営業" },
  { value: 4, text: "営業AS" },
  { value: 5, text: "バイヤー" },
  { value: 6, text: "物流" },
  { value: 7, text: "経理" },
  { value: 8, text: "エフトランス", enum: "FTRANS" },
]);
