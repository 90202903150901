import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "新規登録", enum: "NEW" },
  { value: 2, text: "承認済み", enum: "APPROVED" },
  { value: 3, text: "販売店解禁", enum: "PUBLISHED" },
  { value: 4, text: "受注締め", enum: "ORDERCLOSED" },
  { value: 5, text: "発注締め", enum: "PURCHASECLOSED" },
  { value: 6, text: "追加受付中", enum: "ADDITIONALRECEPTION" },
  { value: 7, text: "販売中", enum: "SALE" },
  { value: 8, text: "販売終了", enum: "SOLDOUT" },
  { value: 9, text: "取り扱い不可", enum: "NOTAVAILABLE" },
]);

// export default createDefault([
//   { value: 1, text: "新規登録", enum: "NEW" },
//   { value: 2, text: "Cafereo承認済み", enum: "APPROVED" },
//   { value: 3, text: "販売中", enum: "SALE" },
//   { value: 4, text: "販売終了", enum: "SOLDOUT" },
//   { value: 5, text: "取り扱い不可", enum: "REJECTED" },
// ]);
