import AccountTypes from "./AccountTypes.js";
import CafereoAffiliations from "./CafereoAffiliations.js";
import ConversionTypes from "./ConversionTypes.js";
import CorporationStatus from "./CorporationStatus.js";
import CorporationTypes from "./CorporationTypes.js";
import CustomerStatus from "./CustomerStatus.js";
import DateSeasons from "./DateSeasons.js";
import Decode from "./Decode.js";
import CafereoDeficiencyStatuses from "./deficiencyStatus/CafereoDeficiencyStatuses";
import MakerDeficiencyStatuses from "./deficiencyStatus/MakerDeficiencyStatuses";
import Deliveries from "./Deliveries.js";
import DeliveryCompanys from "./DeliveryCompanys.js";
import DeliveryStatus from "./DeliveryStatus.js";
import Differences from "./Differences.js";
import EndPoints from "./EndPoints.js";
import ExcessDeficiencyTypes from "./ExcessDeficiencyTypes.js";
import ExcessStatuses from "./ExcessStatuses.js";
import InvoicePeriods from "./InvoicePeriods.js";
import InvoiceTypes from "./InvoiceTypes.js";
import JanDisplay from "./JanDisplay.js";
import MasterStatuses from "./MasterStatuses.js";
import NoticeRule from "./NoticeRule.js";
import OrderTypes from "./OrderTypes.js";
import PaymentTermsClosingDate from "./PaymentTermsClosingDate.js";
import PaymentTermsPaymentDate from "./PaymentTermsPaymentDate.js";
import productConditionTypes from "./productConditionTypes.js";
import ProductListLayouts from "./ProductListLayouts.js";
import ProductOrderTypes from "./ProductOrderTypes.js";
import ProductPeriod from "./ProductPeriod.js";
import ProductRanks from "./ProductRanks.js";
import ProductRecordTypes from "./ProductRecordTypes.js";
import ProductTypes from "./ProductTypes.js";
import ProductUnitTypes from "./ProductUnitTypes.js";
import PropositionTypes from "./PropositionTypes.js";
import PurchaseProductTypes from "./PurchaseProductTypes.js";
import PurchaseTypes from "./PurchaseTypes.js";
import replacementCancelTypes from "./replacementCancelTypes.js";
import returnCancelTypes from "./returnCancelTypes.js";
import ReturnTypes from "./ReturnTypes.js";
import RoundingAttribute from "./RoundingAttribute.js";
import RoundingTypes from "./RoundingTypes.js";
import ScopeTypes from "./ScopeTypes.js";
import ShipHour from "./ShipHour.js";
import ShipmentDayOfWeek from "./ShipmentDayOfWeek.js";
import SlipIssuanceType from "./SlipIssuanceType.js";
import StockOwners from "./StockOwners.js";
import Storages from "./Storages.js";
import SupplierStatus from "./SupplierStatus.js";
import TradingConditions from "./TradingConditions.js";
import TruckingCompany from "./TruckingCompany.js";
import UserPrivileges from "./UserPrivileges.js";
import UserRole from "./UserRole.js";
import WholesaleRatePatterns from "./WholesaleRatePatterns.js";
import BaseStatuses from "./amazonStatus/BaseStatuses.js";
import BaseBillingStatuses from "./billingStatus/BaseBillingStatuses.js";
import CafereoBillingStatuses from "./billingStatus/CafereoBillingStatuses.js";
import VendorBillingStatuses from "./billingStatus/VendorBillingStatuses.js";
import OrderColumns from "./columns/OrderColumns.js";
import PurchaseColumns from "./columns/PurchaseColumns";
import ArrivalAchievmentDifferences from "./difference/ArrivalAchievmentDifferences.js";
import ShipmentAchievmentDifferences from "./difference/ShipmentAchievmentDifferences.js";
import BaseInvoiceStatuses from "./invoiceStatus/BaseInvoiceStatuses.js";
import CafereoInvoiceStatuses from "./invoiceStatus/CafereoInvoiceStatuses.js";
import BaseOrderReplacementStatuses from "./orderReplacementStatus/BaseOrderReplacementStatuses.js";
import CafereoOrderReplacementStatuses from "./orderReplacementStatus/CafereoOrderReplacementStatuses.js";
import VendorOrderReplacementStatuses from "./orderReplacementStatus/VendorOrderReplacementStatuses.js";
import BaseOrderReturnStatuses from "./orderReturnStatus/BaseOrderReturnStatuses.js";
import CafereoOrderReturnStatuses from "./orderReturnStatus/CafereoOrderReturnStatuses.js";
import MakerOrderReturnStatuses from "./orderReturnStatus/MakerOrderReturnStatuses.js";
import VendorOrderReturnStatuses from "./orderReturnStatus/VendorOrderReturnStatuses.js";
import BaseOrderStatuses from "./orderStatus/BaseOrderStatuses.js";
import CafereoOrderStatuses from "./orderStatus/CafereoOrderStatuses.js";
import VendorOrderStatuses from "./orderStatus/VendorOrderStatuses.js";
import BaseProductStatus from "./productStatus/BaseProductStatus.js";
import CafereoProductStatus from "./productStatus/CafereoProductStatus.js";
import MakerProductStatus from "./productStatus/MakerProductStatus.js";
import VendorProductStatus from "./productStatus/VendorProductStatus.js";
import BasePropositionStatus from "./propositionStatus/BasePropositionStatus.js";
import CafereoPropositionStatus from "./propositionStatus/CafereoPropositionStatus.js";
import MakerPropositionStatus from "./propositionStatus/MakerPropositionStatus.js";
import CafereoPurchaseReturnStatuses from "./purchaseReturnStatus/CafereoPurchaseReturnStatuses.js";
import VendorPurchaseReturnStatuses from "./purchaseReturnStatus/VendorPurchaseReturnStatuses.js";
import CafereoPurchaseStatuses from "./purchaseStatus/CafereoPurchaseStatuses.js";
import MakerPurchaseStatuses from "./purchaseStatus/MakerPurchaseStatuses.js";
import CafereoShippingStatuses from "./shippingStatus/CafereoShippingStatuses.js";
import ArrivalStatus from "./ArrivalStatus.js";
import ResponseTypes from "./ResponseTypes.js";

export default {
  constants: {
    AccountTypes: AccountTypes,
    CafereoAffiliations: CafereoAffiliations,
    ConversionTypes: ConversionTypes,
    CorporationStatus: CorporationStatus,
    CorporationTypes: CorporationTypes,
    CustomerStatus: CustomerStatus,
    DateSeasons: DateSeasons,
    Decode: Decode,
    CafereoDeficiencyStatuses: CafereoDeficiencyStatuses,
    MakerDeficiencyStatuses: MakerDeficiencyStatuses,
    Deliveries: Deliveries,
    DeliveryCompanys: DeliveryCompanys,
    DeliveryStatus: DeliveryStatus,
    Differences: Differences,
    EndPoints: EndPoints,
    ExcessDeficiencyTypes: ExcessDeficiencyTypes,
    ExcessStatuses: ExcessStatuses,
    InvoicePeriods: InvoicePeriods,
    InvoiceTypes: InvoiceTypes,
    JanDisplay: JanDisplay,
    MasterStatuses: MasterStatuses,
    NoticeRule: NoticeRule,
    OrderTypes: OrderTypes,
    PaymentTermsClosingDate: PaymentTermsClosingDate,
    PaymentTermsPaymentDate: PaymentTermsPaymentDate,
    productConditionTypes: productConditionTypes,
    ProductListLayouts: ProductListLayouts,
    ProductOrderTypes: ProductOrderTypes,
    ProductPeriod: ProductPeriod,
    ProductRanks: ProductRanks,
    ProductRecordTypes: ProductRecordTypes,
    ProductTypes: ProductTypes,
    ProductUnitTypes: ProductUnitTypes,
    PropositionTypes: PropositionTypes,
    PurchaseProductTypes: PurchaseProductTypes,
    PurchaseTypes: PurchaseTypes,
    replacementCancelTypes: replacementCancelTypes,
    returnCancelTypes: returnCancelTypes,
    ReturnTypes: ReturnTypes,
    RoundingAttribute: RoundingAttribute,
    RoundingTypes: RoundingTypes,
    ScopeTypes: ScopeTypes,
    ShipHour: ShipHour,
    ShipmentDayOfWeek: ShipmentDayOfWeek,
    SlipIssuanceType: SlipIssuanceType,
    StockOwners: StockOwners,
    Storages: Storages,
    SupplierStatus: SupplierStatus,
    TradingConditions: TradingConditions,
    TruckingCompany: TruckingCompany,
    UserPrivileges: UserPrivileges,
    UserRole: UserRole,
    WholesaleRatePatterns: WholesaleRatePatterns,
    BaseStatuses: BaseStatuses,
    BaseBillingStatuses: BaseBillingStatuses,
    CafereoBillingStatuses: CafereoBillingStatuses,
    VendorBillingStatuses: VendorBillingStatuses,
    OrderColumns: OrderColumns,
    PurchaseColumns: PurchaseColumns,
    ArrivalAchievmentDifferences: ArrivalAchievmentDifferences,
    ShipmentAchievmentDifferences: ShipmentAchievmentDifferences,
    BaseInvoiceStatuses: BaseInvoiceStatuses,
    CafereoInvoiceStatuses: CafereoInvoiceStatuses,
    BaseOrderReplacementStatuses: BaseOrderReplacementStatuses,
    CafereoOrderReplacementStatuses: CafereoOrderReplacementStatuses,
    VendorOrderReplacementStatuses: VendorOrderReplacementStatuses,
    BaseOrderReturnStatuses: BaseOrderReturnStatuses,
    CafereoOrderReturnStatuses: CafereoOrderReturnStatuses,
    MakerOrderReturnStatuses: MakerOrderReturnStatuses,
    VendorOrderReturnStatuses: VendorOrderReturnStatuses,
    BaseOrderStatuses: BaseOrderStatuses,
    CafereoOrderStatuses: CafereoOrderStatuses,
    VendorOrderStatuses: VendorOrderStatuses,
    BaseProductStatus: BaseProductStatus,
    CafereoProductStatus: CafereoProductStatus,
    MakerProductStatus: MakerProductStatus,
    VendorProductStatus: VendorProductStatus,
    BasePropositionStatus: BasePropositionStatus,
    CafereoPropositionStatus: CafereoPropositionStatus,
    MakerPropositionStatus: MakerPropositionStatus,
    CafereoPurchaseReturnStatuses: CafereoPurchaseReturnStatuses,
    VendorPurchaseReturnStatuses: VendorPurchaseReturnStatuses,
    CafereoPurchaseStatuses: CafereoPurchaseStatuses,
    MakerPurchaseStatuses: MakerPurchaseStatuses,
    CafereoShippingStatuses: CafereoShippingStatuses,
    ArrivalStatus: ArrivalStatus,
    ResponseTypes: ResponseTypes,
  },
};
