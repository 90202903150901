<template>
  <v-menu absolute bottom :close-on-content-click="false" :max-height="maxHeight" :max-width="maxWidth">
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn icon small class="mx-2" v-bind="attrs" v-on="on" :disabled="disabled">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :size="!nonZoom && hover ? 30 : 15"
                style="transition: all 0.1s 0s linear"
                v-bind="attrs"
                v-on="on"
                >mdi-magnify</v-icon
              >
            </template>
            <span>検索条件</span>
          </v-tooltip>
        </v-btn>
      </v-hover>
    </template>
    <v-card>
      <v-card-title class="pa-2"><v-icon>mdi-magnify</v-icon>検索条件</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-3"><slot></slot></v-card-text>
      <v-divider></v-divider>
      <v-card-actions><v-spacer></v-spacer><v-btn small @click="$emit('search')">検索</v-btn></v-card-actions>
    </v-card>
    <v-overlay :value="isLoading"> </v-overlay>
  </v-menu>
</template>

<script>
export default {
  name: "SearchConditions",
  props: {
    "non-zoom": { type: Boolean },
    "max-width": { type: String },
    "max-height": { type: String, default: "300px" },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
};
</script>
