import { createDefault } from "../utils/ConstUtils";

export default createDefault([
  { value: "S", text: "S" },
  { value: "A", text: "A" },
  { value: "B", text: "B" },
  { value: "C", text: "C" },
  { value: "D", text: "D" },
  { value: "E", text: "E" },
]);
