import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "発注受付中" },
  { value: 2, text: "発注受付終了" },
  { value: 3, text: "追加受付中" },
  { value: 4, text: "販売中" },
  { value: 5, text: "販売終了" },
]);

// export default createDefault([
//   { value: 1, text: "発注受付中" },
//   { value: 2, text: "発注受付終了" },
//   { value: 3, text: "販売中" },
// ]);
