<template>
  <v-card class="pa-2 mt-1 mr-1" tile elevation="1">
    <v-row no-gutters>
      <v-col cols="4" xs="12"> {{ name }} </v-col>
      <v-divider vertical class="mr-2"></v-divider>
      <v-col cols="7" xs="12" :class="classes">
        {{ changeDisplayValue(value) }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "DetailCard",
  props: ["name", "value", "classes"],
  methods: {
    changeDisplayValue(value) {
      var displayValue = value;
      if (displayValue === true) displayValue = "✓";
      else if (displayValue === false) displayValue = "";
      return displayValue;
    },
  },
};
</script>
