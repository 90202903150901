<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn-toggle
        v-on="on"
        :value="active ? 0 : undefined"
        @change="$emit('push', $event === 0)"
        borderless
        rounded
        background-color="transparent"
      >
        <v-hover v-slot="{ hover }">
          <v-btn v-on="on" icon small class="mx-2" :disabled="disabled">
            <v-icon :size="!nonZoom && hover ? 30 : 15" style="transition: all 0.1s 0s linear">{{ icon }}</v-icon>
          </v-btn>
        </v-hover>
      </v-btn-toggle>
    </template>
    <span><slot></slot></span>
    <span v-if="active"><slot name="on"></slot></span>
    <span v-else><slot name="off"></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipIconToggleButton",
  model: { prop: "active", event: "push" },
  props: {
    icon: { type: String },
    active: { type: Boolean },
    "non-zoom": { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
};
</script>
