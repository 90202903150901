import { createDefault } from "../utils/ConstUtils";

const vendorPaymentTermsPaymentDate = [
  { value: 12, text: "当月末" },
  { value: 1, text: "翌10" },
  { value: 2, text: "翌15" },
  { value: 3, text: "翌20" },
  { value: 4, text: "翌25" },
  { value: 5, text: "翌末" },
  { value: 6, text: "翌々5" },
  { value: 7, text: "翌々10" },
  { value: 8, text: "翌々15" },
  { value: 9, text: "翌々20" },
  { value: 10, text: "翌々25" },
  { value: 11, text: "翌々末" },
];
export const VendorPaymentTermsPaymentDate = createDefault(vendorPaymentTermsPaymentDate);

const makerPaymentTermsPaymentDate = [
  { value: 1, text: "翌10" },
  { value: 2, text: "翌15" },
  { value: 3, text: "翌20" },
  { value: 4, text: "翌25" },
  { value: 5, text: "翌末" },
  { value: 6, text: "翌々5" },
  { value: 7, text: "翌々10" },
  { value: 8, text: "翌々15" },
  { value: 9, text: "翌々20" },
  { value: 10, text: "翌々25" },
  { value: 11, text: "翌々末" },
];
export const MakerPaymentTermsPaymentDate = createDefault(makerPaymentTermsPaymentDate);

export default createDefault([
  { value: 0, text: "-" },
  ...vendorPaymentTermsPaymentDate,
  ...makerPaymentTermsPaymentDate,
]);
