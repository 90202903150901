import { createDefault } from "../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "通常品" },
  { value: 2, text: "販促品" },
  { value: 3, text: "特典" },
]);

// export default createDefault([
//   { value: 1, text: "新商品" },
//   { value: 2, text: "再販" },
//   { value: 3, text: "リピート" },
//   { value: 4, text: "販促品" },
//   { value: 5, text: "特典" },
// ]);
