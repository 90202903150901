import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: "AC", text: "AC 発送承諾" },
  { value: "BX", text: "BX 未発売" },
  { value: "IB", text: "IB 入荷待ち" },
  { value: "R2", text: "R2 該当商品なし" },
  { value: "OP", text: "OP 絶版 / 廃版 / 製造中止" },
  { value: "OS", text: "OS 在庫切れ(入荷未定)" },
]);

// export default createDefault([
//   { value: 1, text: "発送承諾", enum: "AC" },
//   { value: 2, text: "未発売", enum: "BX" },
//   { value: 3, text: "入荷待ち", enum: "IB" },
//   { value: 4, text: "該当商品なし", enum: "R2" },
//   { value: 5, text: "絶版 / 廃版 / 製造中止", enum: "OP" },
//   { value: 6, text: "在庫切れ(入荷未定)", enum: "OS" },
// ]);
