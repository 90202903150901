const Cafereo = [
  { value: "CR001", text: "担当者" },
  { value: "CR002", text: "ユーザ管理" },
  { value: "CR003", text: "経理" },
  { value: "CR004", text: "承認者" },
  { value: "CR005", text: "システム管理者" },
];

const FTrans = [{ value: "FT001", text: "担当者" }];

const Maker = [
  { value: "MK001", text: "担当者" },
  { value: "MK002", text: "ユーザ管理者" },
];

const Vendor = [
  { value: "VD001", text: "担当者" },
  { value: "VD002", text: "承認者" },
  { value: "VD003", text: "ユーザ管理者" },
];

const RoleMap = {
  9: Cafereo,
  8: FTrans,
  1: Maker,
  2: Vendor,
};

const Specials = [
  { value: 1, text: "Amazon担当" },
  { value: 2, text: "アニメイト担当" },
  { value: 3, text: "ヨドバシカメラ担当" },
  { value: 4, text: "ソフマップ担当" },
  { value: 5, text: "ヴィレッジバンガード担当" },
  { value: 6, text: "東急ハンズ担当" },
  { value: 7, text: "ロフト担当" },
];

const NamedRoleMap = {};
[...Cafereo, ...FTrans, ...Maker, ...Vendor].reduce((summary, role) => {
  summary[role.value] = role.text;
  return summary;
}, NamedRoleMap);

const ofType = (type) => {
  return type in RoleMap ? RoleMap[type] : [];
};

const ofRoles = (roles) => {
  return roles.map((role) => NamedRoleMap[role]);
};

export default { Cafereo, FTrans, Maker, Vendor, Specials, ofType, ofRoles };
export { Cafereo, FTrans, Maker, Vendor, Specials, ofType, ofRoles };
