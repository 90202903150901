import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "回答待ち", enum: "unanswered", name: "unanswered" },
  { value: 2, text: "承認待ち", enum: "unapproved", name: "unapproved" },
  { value: 3, text: "納品予約待ち", enum: "unreserved", name: "unreserved" },
  { value: 4, text: "納品待ち", enum: "notarrivaled", name: "notarrivaled" },
  { value: 5, text: "納品済み", enum: "notshipped", name: "notshipped" },
  { value: 6, text: "キャンセル", enum: "cancel", name: "cancel" },
]);
